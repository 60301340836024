<template>
    <div class="main">
        <div class="header">
            <div class="header-div" style="flex: 1" @click="goBack">
                <img :src="require('@/assets/退回.png')"  style="width: 20px ;height: 20px"/>
            </div>
            <div class="header-div" style="flex: 5">上传付款码</div>
            <div class="header-div" style="flex: 1"></div>
        </div>
        <div style="margin-top: 100px"></div>
        <!-- 内容1 -->
        <div style="margin-top:20px">
            <el-form label-width="100px" size="small">
                <el-form-item label="手机号:">
                    <el-input v-model="form.mobile" autocomplete="off" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="收款二维码:">
                    <el-upload class="avatar-uploader" :action=UPLOAD_URL :show-file-list="false"
                               :on-success="handleImgUploadSuccess">
                        <img v-if="form.qrCode" :src="form.qrCode" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

            </el-form>

            <div style="display: flex;justify-content: center; align-items: center;margin-top:40px">
                <el-button type="primary" round style="width: 70%;" size="medium" @click="uploadMember">提 交
                </el-button>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "UploadPage",
        data() {
            return {
                form: {qrCode: ''},
            };
        },
        created() {
            this.load();
        },
        methods: {
            //列表展示
            load() {
            },
            async uploadMember() {
                // 假设我们使用navigator.userAgent来获取用户代理字符串
                const userAgent = navigator.userAgent;

                console.log(userAgent)
                // if (1==1){
                //     return;
                // }
                this.form.deviceId = userAgent
                this.request.post("/app/member/uploadMember", this.form).then(res => {
                    console.log(res)
                    if (res.code === '200') {
                        localStorage.setItem("membertoken", res.data);  // 存储用户信息到浏览器
                        this.$message.success("保存成功")
                        this.$router.push("/")
                    }
                })
                // const response = await this.request.post("/app/member/uploadMember", this.form);
                // if (response.code === '200') {
                //     localStorage.setItem("membertoken", res.data);  // 存储用户信息到浏览器
                //     this.$message.success("保存成功")
                //     this.$router.push("/")
                // }
            },
            handleImgUploadSuccess(res) {
                this.form.qrCode = res
            },
            goBack() {
                window.history.back();
            }
        }
    };
</script>

<style scoped>
    * {
        font-family: 微软雅黑;
        font-size: 14px;
    }

    .header {
        position: fixed;
        top: 0;
        z-index: 1000; /* 确保标题栏位于其他内容之上 */
        background-color: #000;
        color: #fff;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .header-div{
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .plan_list_text {
        font-size: 14px;
        padding: 2px 5px;
        overflow: hidden;
        word-break: break-all; /* break-all(允许在单词内换行。) */
        text-overflow: ellipsis; /* 超出部分省略号 */
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 1; /** 显示的行数 **/
    }

    .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        width: 138px;
        height: 138px;
        text-align: left;
        padding-bottom: 10px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 138px;
        height: 138px;
        line-height: 138px;
        text-align: center;
    }

    .avatar {
        width: 138px;
        height: 138px;
        display: block;
    }
</style>

